import { serviceInterfaces } from '../../_models';

import updateAccount from '../../_mixins/updateAccount';

export default {
  name: 'BindingInformation',
  mixins: [updateAccount],
  data() {
    return {
      showPassword: false,
      serviceInterfaces,
    };
  },
  computed: {
    isInvalidForm() {
      const validationItems = ['bindingInterface', 'bindingUrl'];
      return validationItems.some(item => (
        this.editableAccountData[item] === null || !`${this.editableAccountData[item]}`
      ));
    },
  },
};
