import { mapState } from 'vuex';

export default {
  data() {
    return {
      editableAccountData: {},
    };
  },
  computed: {
    ...mapState('accounts', ['account']),
  },
  watch: {
    account: {
      handler(newValue) {
        Object.assign(this, {
          editableAccountData: JSON.parse(JSON.stringify(newValue)),
        });
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    showEditPrompt() {
      this.$VBlackerTheme.alert.warning({
        text: 'Update account?',
      }, async () => this.$emit('submit', this.editableAccountData));
    },
  },
};
