import { mapState } from 'vuex';

import { genGUID } from '@helpers';

import updateAccount from '../../_mixins/updateAccount';

export default {
  name: 'AccountInformation',
  mixins: [updateAccount],
  computed: {
    ...mapState('enums', {
      accountTypes(state) {
        const { accountTypes = [] } = state.enums;
        return accountTypes.map(({ name: text, value }) => ({ text, value }));
      },
    }),
    isInvalidForm() {
      const validationItems = ['accountApiKey', 'accountType'];
      return validationItems.some(item => !this.editableAccountData[item]);
    },
  },
  methods: {
    genGUID() {
      this.$set(this.editableAccountData, 'accountApiKey', genGUID());
    },
  },
};
