<template>
  <v-layout
    align-center
    justify-end
  >
    <v-btn
      :loading="loading"
      x-small
      outlined
      class="mr-3"
      color="success"
      @click="setupXpress"
    >
      Setup Xpress
    </v-btn>

    <vbt-badge
      v-if="xpressEnabled"
      color="success"
      class="mr-3"
      small
    >
      Xpress
    </vbt-badge>
  </v-layout>
</template>

<script>
import { mapMutations } from 'vuex';

import { wrapToLoadingFn } from '@helpers';

import { AccountsApiService } from '../_services';

export default {
  name: 'SetupXpress',

  props: {
    xpressEnabled: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    ...mapMutations('accounts', ['setAccountXpressEnabled']),

    setupXpress() {
      this.wrapToLoadingFn({
        loadingFlagName: 'loading',
        req: AccountsApiService.setupXpress.bind({}, { accountId: this.$route.params.id }),
        onSuccess: this.setAccountXpressEnabled,
      });
    },

    wrapToLoadingFn,
  },
};
</script>
