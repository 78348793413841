import updateAccount from '../../_mixins/updateAccount';

export default {
  name: 'CustomConfiguration',
  mixins: [updateAccount],
  computed: {
    isInvalidForm() {
      const config = this.editableAccountData.bindingConfiguration || [];
      const allEmpty = config.every((({ name, value }) => !name && !value));
      if (allEmpty) {
        return false;
      }
      return config.some(({ name, value }) => (!name && value) || (name && !value));
    },
  },
  watch: {
    account: {
      handler(newValue) {
        Object.assign(this, {
          editableAccountData: {
            ...newValue,
            bindingConfiguration: newValue.bindingConfiguration
            && newValue.bindingConfiguration.length
              ? JSON.parse(JSON.stringify(newValue.bindingConfiguration))
              : [{ name: '', value: '' }],
          },
        });
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    addParameters() {
      this.editableAccountData.bindingConfiguration.push({ name: '', value: '' });
    },
    removeParameters(index) {
      const items = this.editableAccountData.bindingConfiguration;
      items.splice(index, 1);
      if (!items.length) {
        items.push({ name: '', value: '' });
      }
    },
    checkFieldError(index, fieldValue) {
      const { name, value } = this.editableAccountData.bindingConfiguration[index];
      if (!name && !value) {
        return false;
      }
      return !fieldValue;
    },
    showEditPrompt() {
      const response = {
        ...this.editableAccountData,
        bindingConfiguration: this.editableAccountData.bindingConfiguration
          .filter(({ name, value }) => Boolean(name) && Boolean(value)),
      };
      this.$VBlackerTheme.alert.warning({
        text: 'Update account?',
      }, async () => this.$emit('submit', response));
    },
  },
};
