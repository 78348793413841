import HTTP from '@api';

import { generateXHRError } from '@helpers';

export const AccountsApiService = {
  async setupXpress({ accountId }) {
    try {
      const { data } = await HTTP.post(`accounts/${accountId}/setup-xpress`);

      return data || {};
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },
};
