import { mapState, mapActions } from 'vuex';

import { useDataLoading } from '@mixins/factories';

import SetupXpress from '../../_components/SetupXpress.vue';

import AccountInformation from '../../_components/accountInformation';
import BindingInformation from '../../_components/bindingInformation';
import CustomConfiguration from '../../_components/customConfiguration';

const dataLoadingOptions = {
  getterName: 'getAccount', searchable: false, paged: false, parameterize: true,
};
const tabsConfig = Object.freeze([
  { text: 'Account information', key: 'AccountInformation' },
  { text: 'Binding information', key: 'BindingInformation' },
  { text: 'Custom configuration', key: 'CustomConfiguration' },
]);

export default {
  name: 'Account',

  mixins: [
    useDataLoading(dataLoadingOptions),
  ],

  components: {
    SetupXpress,
    AccountInformation,
    BindingInformation,
    CustomConfiguration,
  },

  data() {
    return {
      tabsConfig,
      currentTab: 'info',
    };
  },

  computed: {
    ...mapState('accounts', {
      xpressEnabled: ({ account: { xpressEnabled } }) => Boolean(xpressEnabled),
    }),
  },

  methods: {
    ...mapActions('accounts', ['getAccount', 'updateAccount']),

    async onAccountEditSubmit(editableAccountData) {
      this.$VBlackerTheme.openLoadingSpinner();
      try {
        await this.updateAccount({
          id: this.$route.params.id,
          editableAccountData,
        });
        this.$VBlackerTheme.notification.success('Account successfully updated');
        this.getData();
      } catch (e) {
        this.$VBlackerTheme.notification.error(e);
      } finally {
        this.$VBlackerTheme.closeLoadingSpinner();
      }
    },

    closeAccountEditor() {
      this.$router.push({ name: 'accounts', query: { pageNum: '1' } });
    },
  },
};
